@import '../../styles/mixins';

.wrap-container-login {
  display        : flex;
  align-items    : center;
  justify-content: center;
  min-height     : calc(100vh - 20px);
}

.container-login {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  gap           : 36px;
  padding       : 15px 0;

  @include media(width, (calc(100% - 32px), ""));

  .logo {
    background: url("~/public/img/login/logo.svg") 50% 50%/contain no-repeat;
    box-shadow: none;
    @include media(width, (207px, 267px));
    @include media(height, (58px, 75px));
  }

  .content-login {
    background   : var(--base-0);
    border-radius: 20px;
    box-shadow   : 4px 4px 10px 0px rgba(44, 44, 44, 0.10);
    display      : flex;

    @include media(margin-left, ("", -80px));
    @include media(flex-direction, (column, ""));
    @include media(width, (100%, ""));
    @include media(max-width, (100%, 460px, ""));

    .left {
      display        : flex;
      padding        : 40px;
      flex-direction : column;
      justify-content: center;
      align-items    : center;
      gap            : 16px;
      align-self     : stretch;

      @include media(padding, (16px, 40px, 40px));
      @include media(width, (100%, 408px));
    }

    .right {
      display    : flex;
      align-items: center;

      @include media(justify-content, (center, ""));
      @include media(padding, (16px, 40px 0));
      @include media(width, (100%, 332px));

      img {
        width : 422px;
        height: 255.458px;

        @include media(width, (280px, 422px));
        @include media(height, (168.995px, 255.458px));
      }
    }
  }
}

.wrap-container {
  @include media(padding-top, (var(--header-mobile-height), var(--header-pc-height)));
  @include media(padding-left, (0, var(--nav-pc-width-hide)));
}

.container {
  max-width: var(--max-width-content);
  margin   : auto;
  @include media(padding, (10px 10px, 10px 16px, 28px 32px));
}

.content {
  display              : grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap                  : 5px;

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}