@import "../styles/mixins";

.custom-modal {
  background   : var(--base-0);
  max-width    : 600px;
  margin       : 32px auto;
  border-radius: 8px;

  @include media(padding, (20px 16px, 32px 40px));
  @include media(margin, (0 auto, 32px auto));

  display       : flex;
  flex-direction: column;
  gap           : 24px;

  position: relative;
  overflow: hidden;

  &.large {
    max-width: 800px;
  }

  &.big {
    max-width: 900px;
  }

  &__bg {
    position  : fixed;
    top       : 0;
    left      : 0;
    width     : 100%;
    height    : 100%;
    background: var(--transparent-64);
    z-index   : 100;
    overflow  : auto;
  }

  &__header {
    display    : flex;
    align-items: center;
    gap        : 8px;
  }

  &__footer {
    display        : flex;
    align-items    : center;
    justify-content: flex-end;
    gap            : 10px;
  }

  .icon {
    height: 24px;
    width : 24px;
  }

  &__close {
    position: absolute;
    right   : 0;
    top     : 0;
    z-index : 10;
  }

  .chart-wrap {
    overflow     : hidden;
    border-radius: 8px;
    border       : 1px solid #ddd;

    #tradingview-chart {
      margin: -1px;
      width : calc(100% + 2px);
    }
  }
}