.btn {
  border         : none;
  outline        : none;
  box-shadow     : none;
  display        : inline-flex;
  align-items    : center;
  justify-content: center;
  gap            : 5px;

  cursor    : pointer;
  transition: var(--transition);

  &:disabled {
    cursor          : not-allowed;
    background-color: var(--base-300) !important;

    &:hover {
      background-color: var(--base-300) !important;
    }
  }

  &:not(.icon) {
    height       : 48px;
    min-width    : 48px;
    border-radius: 8px;
    padding      : 12px 24px;
    font-size    : 16px;
    background   : var(--primary-500);
    color        : #fff;

    &:hover {
      background: var(--primary-700);
    }
  }

  &.icon {
    display    : inline-flex;
    align-items: center;
    background : transparent;
    color      : var(--base-600);

    svg {
      height: 26px;
      width : 26px;
    }

    &:hover {
      color: var(--primary-500)
    }
  }

  &.small {
    font-size: 14px;
    height   : 36px;
    min-width: 36px;
    padding  : 0 16px;
  }

  &.border {
    background : #fff;
    border     : 1px solid;
    color      : var(--primary-500);
    font-weight: 500;

    &.gray {
      border-color: var(--base-400);
      color       : var(--base-600);
    }

    &:hover {
      color: #fff
    }
  }

  &.filter {
    margin-left    : 12px;
    padding        : 0;
    justify-content: center;

    svg {
      height: 26px;
      width : 25px;
    }
  }

  &.-danger {
    color: var(--accent-red-500);

    &:hover {
      background: var(--accent-red-500);
      color     : #fff;
    }
  }
}

.toggle {
  cursor       : pointer;
  width        : 40px;
  height       : 20px;
  position     : relative;
  border-radius: 10px;
  padding      : 3px;
  display      : flex;
  background   : var(--base-300);

  &::before {
    background   : var(--base-500);
    width        : 14px;
    height       : 14px;
    border-radius: 50%;
    content      : '';
    position     : relative;
    display      : block;
  }

  &.-active {
    justify-content: end;
    background     : var(--primary-100);

    &::before {
      background: var(--primary-500);
    }
  }
}