.add-bot-modal {
  display       : flex;
  flex-direction: column;
  gap           : 15px;

  .item {
    cursor       : pointer;
    transition   : var(--transition);
    padding      : 12px;
    border-radius: 8px;
    background   : var(--base-100);
    border       : 1px solid var(--base-100);

    &:hover {
      border-color: var(--primary-300);
    }

    &__title {
      display    : flex;
      font-size  : 18px;
      font-weight: 700;
      gap        : 8px;
    }

    &__text {
      margin-top: 15px;
    }

    &__icon {
      color: var(--base-600);
    }

    &__tags {
      display      : flex;
      gap          : 5px;
      margin-bottom: 5px;

      div {
        border-radius         : 11px;
        // background         : var(--primary-100);
        // padding            : 2px 5px;
        // font-size    : 12px;
        // font-weight     : 600;
        display               : flex;
        align-items           : center;
        // border             : 1px solid var(--primary-500);

      }
    }
  }
}