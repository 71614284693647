.card {
  border-radius: 12px;
  background   : var(--base-0);
  box-shadow   : 4px 4px 10px 0px rgba(44, 44, 44, 0.10);

  &__header {
    padding: 24px 24px 0px 24px;
    @include media(padding, (8px, 24px 24px 0px 24px, 24px 24px 0px 24px));
  }

  &__title {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    font-weight    : 500;

    .icon {
      height: 24px;
      width : 24px;

      &.--small {
        height: 20px;
        width : 20px;
      }

      &.--info {
        cursor: help;
      }
    }
  }

  &__body {
    @include media(padding, (8px, 8px 16px, 8px 16px));

    .card__coin-icon {
      height: 32px;
      width : 32px;
    }
  }
}