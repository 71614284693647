@import '../styles/mixins';

.bonuses {
  display   : grid;
  gap       : 16px;
  margin-top: 8px;
  @include media(grid-template-columns, (1fr, 1fr 1fr, 1fr 1fr));
}

.helper-text {
  font-size  : 14px;
  font-weight: 500;
  margin-top : 16px;
}

.buttons-container {
  margin-top     : 16px;
  display        : flex;
  gap            : 16px;
  justify-content: flex-end;
  @include media(flex-direction, (column-reverse, row, row));
}