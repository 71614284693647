.add-wallets {
  display              : grid;
  grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
  gap                  : 8px;

  &__step1 {
    margin-top    : 20px;
    display       : flex;
    flex-direction: column;
    gap           : 12px;
    overflow      : hidden;
  }

  &__step2 {
    display       : flex;
    flex-direction: column;
    gap           : 24px;
  }

  &__item {
    padding      : 8px 12px;
    border-radius: 8px;
    width        : 100%;
    display      : flex;
    align-items  : center;
    background   : var(--base-100);
    border       : 1px solid var(--base-100);
    cursor       : pointer;
    transition   : var(--transition);
    height       : 46px;

    &:hover {
      border    : 1px solid var(--primary-300);
      box-shadow: 2px 2px 16px 0px rgba(44, 44, 44, 0.15);
    }

    img {
      width: 28px;
    }
  }

  &__name {
    overflow     : hidden;
    text-overflow: ellipsis;
    white-space  : nowrap;
  }

  &__name {
    padding-left: 8px;
  }
}