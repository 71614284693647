.percent-price-block {
  position     : relative;
  height       : 6px;
  border-radius: 3px;
  background   : var(--base-300);
  margin-top   : 10px;
  font-size    : 11px;
  font-weight  : 600;

  &__price {
    position: absolute;
    z-index : 1;
    top     : 8px;

    &.--min {
      left: 0px;
    }

    &.--max {
      right: 0px;
    }
  }

  &__center {
    position : absolute;
    left     : 50%;
    transform: translate(-50%, 0);
    z-index  : 1;
    top      : 8px
  }

  &__interval {
    position: absolute;
    right   : 0px;
    z-index : 1;
    bottom  : 8px;

  }

  &__line {
    transition   : 0.3s;
    border-Radius: 3px;
    position     : absolute;
    height       : 100%;
    background   : var(--primary-500);
    left         : 0;
    bottom       : 0;
  }

  &__information {
    margin-top : 24px;
    font-size  : 12px;
    font-weight: 500;
  }
}