@import '../../styles/mixins';

.bots-page {
  &__top {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;

    @include media(flex-direction, (column, row, row));
    @include media(align-items, (start, center));
    @include media(padding-top, (0, 0));
    @include media(display, (block, flex));
  }

  &__header {
    display        : flex;
    justify-content: space-between;
    flex-wrap      : wrap;

    @include media(flex-direction, (column, row, row));
    @include media(align-items, (start, center));
    @include media(margin-top, (10px, 30px));
  }

  &__title {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    gap            : 10px;
    @include media(width, (100%, ""));
  }

  &__filters {
    display    : flex;
    align-items: center;
    gap        : 12px;

    @include media(width, (100%, auto));
    @include media(padding-top, (16px, 0));
  }

  &__content {
    margin-top           : 20px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  &__buttons {
    margin-top: 15px;
  }
}