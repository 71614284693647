:root {
  --base-0  : #fff;
  --base-100: #F4F5FA;
  --base-200: #F2F3F3;
  --base-300: #DDE0E8;
  --base-400: #CCD0DB;
  --base-500: #B4B8C4;
  --base-600: #888E9D;
  --base-700: #6A6F7F;
  --base-800: #525866;
  --base-900: #333740;

  --primary-100: #F1DEFE;
  --primary-300: #D596FF;
  --primary-500: #9800FF;
  --primary-700: #782BAC;

  --transparent-64: rgba(51, 55, 64, 0.64);

  --accent-green-500: #57b214;
  --accent-red-500  : #E4565A;

  --transition: .2s;

  --header-pc-height    : 60px;
  --header-mobile-height: 60px;

  --nav-pc-width     : 280px;
  --nav-pc-width-hide: 60px;
  --nav-mobile-width : 300px;
  --max-width-content: 1200px;

}