@import '../styles/mixins';

.payments-page {
  &__header {
    @include media(margin-top, (10px, 16px))
  }

  &__body {
    margin-top: 20px;
    display   : grid;
    @include media(grid-template-columns, (repeat(auto-fit, minmax(250px, 1fr)), repeat(auto-fit, minmax(480px, 1fr)), repeat(auto-fit, minmax(480px, 1fr))));
    gap: 5px;
  }


  .body__column {
    display              : grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap                  : 5px;
  }
}