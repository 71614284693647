@import '../styles/mixins';

.widget-wallets {
  &__statistics {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    font-size      : 14px;

    @include media(padding, (8px 0, 16px 0));

    h3 {
      font-size: 24px;
    }
  }

  &__table {
    width          : 100%;
    border-collapse: collapse;
    font-weight    : 500;

    td {
      padding       : 12px 6px;
      vertical-align: middle;
      white-space   : nowrap;
      border-bottom : 1px solid var(--base-200);
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  &__line {
    margin-top   : 5px;
    border-radius: 2px;
    height       : 4px;
    background   : var(--base-200);

    div {
      border-radius: 2px;
      height       : 4px;
      background   : var(--primary-500);
    }
  }
}