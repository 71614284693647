@import '../../styles/mixins';

nav {
  position      : fixed;
  background    : #fff;
  flex-direction: column;
  box-shadow    : 2px 2px 16px 0px rgba(44, 44, 44, 0.15);
  display       : flex;
  transition    : var(--transition);
  z-index       : 99;
  bottom        : 0;

  @include media(left, (calc(0px - var(--nav-mobile-width)), 0));
  // @include media(height, (100vh, calc(100vh - var(--header-pc-height))));
  @include media(top, (0, var(--header-pc-height)));
  @include media(width, (var(--nav-mobile-width), var(--nav-pc-width-hide)));

  @media print {
    display: none !important;
  }

  &:hover {
    @include media(width, (var(--nav-mobile-width), var(--nav-pc-width)));

    .tarif {
      visibility: visible;
      opacity   : 1;
    }

    .bottom {

      .lang,
      .btn span {
        visibility: visible;
        opacity   : 1;
      }
    }

    .menu {
      overflow-y: auto;

      a {
        span {
          visibility: visible;
          opacity   : 1;
        }
      }
    }
  }

  &.show {
    left: 0;

    .nav-bg {
      pointer-events: all;
      background    : rgba(0, 0, 0, 0.5);
    }
  }

  .account {
    padding    : 16px 16px 16px 25px;
    background : var(--base-900);
    display    : flex;
    align-items: center;
    color      : var(--base-0);
    font-weight: 400;
    min-height : var(--header-mobile-height);
    @include media(display, (flex, none));

    &__name {
      white-space  : nowrap;
      overflow     : hidden;
      text-overflow: ellipsis;
    }

    .icon {
      margin-right: 10px;
      min-height  : 24px;
      min-width   : 24px;

    }

    &:hover {
      color: var(--primary-300)
    }
  }

  .menu {
    padding   : 16px 0;
    height    : 100%;
    overflow  : auto;
    overflow-x: hidden;
    overflow-y: hidden;

    .dropmenu {
      a {
        padding: 8px 32px 8px 68px;
      }
    }

    a {
      color      : var(--base-900);
      display    : flex;
      align-items: center;
      height     : 48px;
      padding    : 8px 13px;
      box-shadow : none;
      position   : relative;
      white-space: nowrap;
      transition : .3s;

      .wallet-icon {
        height      : 24px;
        width       : 24px;
        margin-right: 12px;
      }

      .icon-wrap {
        display        : flex;
        height         : 34px;
        width          : 34px;
        min-width      : 34px;
        align-items    : center;
        justify-content: center;
        margin-right   : 12px;
        background     : var(--base-100);
        border-radius  : 50%;
      }

      .icon {
        height    : 20px;
        width     : 20px;
        color     : var(--base-600);
        transition: var(--transition);

        &.plus {
          margin-left: auto;

          &:hover {
            color: var(--primary-500);
          }
        }
      }

      span {
        transition: .3s;
        @include media(visibility, (visibility, hidden));
        @include media(opacity, (1, 0));
      }

      &::before {
        content      : "";
        width        : 0px;
        height       : 36px;
        position     : absolute;
        top          : 6px;
        left         : 0;
        border-radius: 0px 4px 4px 0px;
        background   : var(--primary-500);
        transition   : var(--transition);

      }

      &.active,
      &:hover {
        font-weight: 500;
        box-shadow : none;

        &:not(:hover)::before {
          content   : "";
          width     : 4px;
          background: var(--primary-500);
        }

        .icon:not(.plus) {
          color: var(--primary-500);
        }
      }
    }
  }

  .bottom {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    background     : var(--base-900);
    padding        : 8px 16px;
    min-height     : 56px;

    .btn {
      span {
        @include media(visibility, (visibility, hidden));
        @include media(opacity, (1, 0));
        transition: .3s;
      }

      &:hover {
        color     : var(--base-0);
        box-shadow: none;
      }
    }

    svg.icon {
      height       : 24px;
      width        : 24px;
      padding-right: 5px;
    }

    .lang {
      display       : flex;
      align-items   : center;
      font-weight   : 500;
      font-size     : 14px;
      text-transform: uppercase;
      @include media(visibility, (visibility, hidden));
      @include media(opacity, (1, 0));
      transition: .3s;

      img {
        margin-left: 5px;
        width      : 25px;
      }
    }
  }

  .tarif {
    padding   : 15px 20px;
    box-shadow: 0 -2px 0 var(--base-100);
    color     : var(--base-800);
    @include media(visibility, (visibility, hidden));
    @include media(opacity, (1, 0));
    transition: .3s;

    &-title {
      transform      : var(--transition);
      display        : flex;
      justify-content: space-between;
      font-size      : 16px;
      font-weight    : 700;
      cursor         : pointer;
      white-space    : nowrap;

      &:hover {
        color: var(--primary-500);
      }
    }

    &-content {
      font-size: 14px;
    }

    &-item {
      margin         : 8px 0;
      display        : flex;
      justify-content: space-between;
      white-space    : nowrap;

      &:first-child {
        margin-top: 16px;
      }
    }
  }

  .count {
    font-size      : 12px;
    min-width      : 18px;
    background     : var(--base-600);
    border-radius  : 50%;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    min-height     : 18px;
    color          : #fff;
    font-weight    : 500;
  }
}

.nav-bg {
  transition    : var(--transition);
  background    : rgba(0, 0, 0, 0.0);
  position      : fixed;
  top           : 0;
  width         : 100%;
  height        : 100%;
  pointer-events: none;
  @include media(left, (var(--nav-mobile-width), var(--nav-pc-width)));
}