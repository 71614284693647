.payment-card {
  padding: 16px 12px;
  border : 1px solid var(--base-0);

  &.active {
    border-color: var(--primary-500);

    .rate__title {
      color: var(--primary-500);
    }
  }

  &__title {
    font-size      : 18px;
    font-weight    : 700;
    display        : flex;
    align-items    : center;
    justify-content: center;

    .icon {
      color: var(--primary-500)
    }
  }

  &__body {
    margin-top    : 12px;
    display       : flex;
    flex-direction: column;
    gap           : 6px;
  }

  &__label {
    display        : flex;
    justify-content: space-between;
  }

  .label {
    font-size  : 14px;
    font-weight: 500;

    p {
      font-size  : 12px;
      font-weight: 400;
    }

    &__value {
      text-align : right;
      white-space: nowrap;
      display    : flex;
    }
  }

  &__price {
    margin-top : 12px;
    font-size  : 16px;
    font-weight: 700;
    text-align : center;
  }

  &__buy {
    margin-top : 12px;
    font-size  : 16px;
    font-weight: 500;
    text-align : center;
  }
}