.share-block {
  width     : 880px;
  background: var(--base-200);

  &__wrap {
    position: fixed;
    left    : -1000px;
  }

  &__header {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding        : 0 40px;
    height         : 134px;
    font-size      : 20px;
    color          : #fff;
    background     : var(--base-900);
  }

  &__logo {
    width: 200px;

    img {
      width: 100%;
    }
  }

  &__url {
    gap            : 10px;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
  }

  &__content {
    padding      : 40px;
    display      : flex;
    gap          : 30px;
    margin-top   : 80px;
    margin-bottom: 80px;
  }

  &__info {
    width         : 100%;
    gap           : 30px;
    display       : flex;
    flex-direction: column;
  }

  &__title {
    text-align : center;
    font-size  : 40px;
    font-weight: 700;
  }

  &__indicator {
    background     : var(--base-0);
    display        : flex;
    justify-content: center;
    font-size      : 28px;
    padding        : 20px;
    font-weight    : 700;
    border-radius  : 20px;

    &.-big {
      color    : var(--primary-500);
      font-size: 54px;
      padding  : 40px 20px;
    }
  }

  &__robo {
    max-width          : 250px;
    min-width          : 250px;
    background-size    : contain;
    background-position: 50% 50%;
    background-repeat  : no-repeat;
  }

  &__description {
    display       : flex;
    flex-direction: column;
    gap           : 10px;
  }

  &__description-item {
    color          : var(--base-800);
    font-size      : 20px;
    font-weight    : 500;
    display        : flex;
    justify-content: space-between;
    background     : var(--base-200);

    span {
      text-wrap: nowrap;
    }
  }

  &__footer {
    padding    : 40px;
    background : var(--base-900);
    display    : flex;
    gap        : 30px;
    align-items: center;
  }

  &__footer-text {
    color         : #fff;
    font-size     : 24px;
    font-weight   : 500;
    display       : flex;
    gap           : 10px;
    flex-direction: column;


    .-big {
      font-size  : 32px;
      font-weight: 700;
    }
  }
}