@import "mixins";
@import "variables";
@import "icons";
@import "buttons";
@import "card";
@import "table";
@import "tabs";
@import "global";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing : border-box;
  padding    : 0;
  margin     : 0;
  font-family: Montserrat;
  line-height: initial;
}

body {
  background: var(--base-100);
  color     : var(--base-900);
  overflow-y: scroll;
}

h1 {
  color      : var(--base-900);
  font-size  : 36px;
  font-weight: 700;
}

h2 {
  color      : var(--base-900);
  font-size  : 28px;
  font-weight: 700;
}

h3 {
  color      : var(--base-900);
  font-size  : 24px;
  font-weight: 700;

  @include media(font-size, (18px, 24px));
  @include media(line-height, (24px, 36px));
}

h4 {
  color      : var(--base-900);
  font-size  : 18px;
  font-weight: 700;
}

h5 {
  color      : var(--base-900);
  font-size  : 16px;
  font-weight: 500;
}

a,
.link {
  transition     : var(--transition);
  color          : var(--primary-500);
  text-decoration: none;
  font-weight    : 500;
  box-shadow     : none;
  transition     : .2s;
  cursor         : pointer;

  &:hover:not(.-no-hover) {
    box-shadow: 0 1px 0;
  }
}

.hover {
  &:hover {
    transition: var(--transition);
    color     : var(--primary-500);
  }
}

hr {
  border       : none;
  border-bottom: 1px solid var(--base-200)
}

//animation for vue
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity  : 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}

.admin-show-user {
  transition   : .3s;
  position     : fixed;
  z-index      : 30;
  background   : #fff;
  border-radius: 8px;
  padding      : 10px;
  bottom       : 10px;
  right        : 10px;
  box-shadow   : 0 0 15px rgba(0, 0, 0, .3);

  &:hover {
    opacity: 0.1
  }
}