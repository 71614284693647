.bold {
  font-weight: 700;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}


.fs-14 {
  font-size: 14px;
}

.f-small {
  font-size: 12px
}

.w-fill {
  width: 100%;
}

.grid-fill {
  grid-column: 1/-1;
}

.a-right {
  text-align: right !important;
}

.a-left {
  text-align: left !important;
}

.a-center {
  text-align: center !important;
}

.ml-12 {
  margin-left: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.pt-24 {
  padding-top: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.c-green {
  color: var(--accent-green-500);
}

.c-red {
  color: var(--accent-red-500);
}

.c-base-400 {
  color: var(--base-400);
}

.c-base-600 {
  color: var(--base-600);
}

.c-primary-300 {
  color: var(--primary-300);
}

.c-primary-500 {
  color: var(--primary-500);
}


.c-base-0 {
  color: var(--base-0);
}

.underline {
  text-decoration: underline;
}

.flex {
  display: flex;
}

.opacity-05 {
  opacity: 0.5;
}

.ai-center {
  @extend .flex;
  align-items: center;
}

.description {
  font-size: 14px;
  color    : var(--base-700, #6A6F7F);
}

.error-validator {
  font-size: 14px;
  color    : var(--accent-red-500);
}

.preloader {
  display        : flex;
  align-items    : center;
  justify-content: center;
  width          : 100%;

  img {
    width: 70px;
  }
}

.pointer {
  cursor: pointer;
}

.pc-hidden {
  @include media(display, ("", "", none));
}

.tablet-hidden {
  @include media(display, ("", none, ""));
}

.mobile-hidden {
  @include media(display, (none, "", ""));
}

.close-container {
  display        : flex;
  justify-content: end;
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}