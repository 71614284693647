@import '../styles/mixins';

.profile {
  display       : flex;
  flex-direction: column;
  gap           : 15px;
}

.profile-data {
  display: grid;
  gap    : 16px;
  padding: 24px;
  @include media(grid-template-columns, (1fr, 1fr 1fr, 1fr 1fr));
}

.profile-email {
  display: flex;
  gap    : 16px;
  @include media(flex-direction, (column, row, row));
}

.telegram {
  margin-top : 8px;
  display    : flex;
  align-items: center;
  gap        : 16px;
  @include media(flex-direction, (column, row, row));
}

.helper {
  @include media(align-self, (start, center, center));
  font-size  : 14px;
  font-weight: 500;
}

.telegram-subscribe {
  @include media(width, (100%, auto, auto));
}

.telegram-unsubscribe {
  display    : flex;
  align-items: center;
  gap        : 16px;
  @include media(flex-direction, (column, row, row));

  >* {
    flex     : 1 1 0;
    min-width: 0;
  }

  button {
    @include media(justify-content, (center, start, start));
  }
}

.info-line {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  width          : 100%;
}

.telegram-chanel {
  display    : flex;
  align-items: center;
  gap        : 5px;
  margin-top : 24px;

  .-icon {
    min-height: 25px;
    min-width : 25px;
  }
}