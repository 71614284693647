@import "./mixins";

table {
  width: 100%;
}

.table-shadow {
  width          : 100%;
  border-collapse: collapse;
  font-size      : 14px;

  tr {
    th {
      height     : 28px;
      font-weight: 500;
      text-align : left;
      color      : var(--base-700);
      padding    : 4px;

      &:first-child {
        @include media(padding-left, (8px, 16px, 16px))
      }

      &:last-child {
        @include media(padding-right, (8px, 16px, 16px))
      }
    }

    td {
      height       : 60px;
      background   : #fff;
      box-shadow   : inset 0px -8px 0px 0px #f4f5fa;
      overflow     : hidden;
      text-overflow: ellipsis;
      padding      : 4px;

      @include media(padding-bottom, (16px, 16px, 16px));

      &:first-child {
        border-top-left-radius   : 8px;
        border-bottom-left-radius: 8px;

        @include media(padding-left, (8px, 16px, 16px));
      }

      &:last-child {
        border-top-right-radius   : 8px;
        border-bottom-right-radius: 8px;

        @include media(padding-right, (8px, 16px, 16px));
      }

      .arrow-percent {
        display        : flex;
        align-items    : center;
        justify-content: end;

        &.c-green::before {
          content  : '▲';
          font-size: 12px;
        }

        &.c-red::before {
          content  : '▼';
          font-size: 12px;
        }
      }

      .visible-hover {
        opacity   : 0;
        visibility: hidden;
        transition: var(--transition);
      }
    }

    &:hover {
      .visible-hover {
        opacity   : 1;
        visibility: visible;
      }
    }

    &.-hover {
      td {
        background: var(--base-300);
      }
    }

    &.-test {
      td {
        background: #fef9de;
      }
    }
  }
}