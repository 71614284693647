.withdraw-requests-modal {
  display       : flex;
  flex-direction: column;
  gap           : 15px;

  .item {
    cursor       : pointer;
    transition   : var(--transition);
    padding      : 12px;
    border-radius: 8px;
    background   : var(--base-100);
    border       : 1px solid var(--base-100);

    &:hover {
      border-color: var(--primary-300);
    }

    &__title {
      display    : flex;
      font-size  : 18px;
      font-weight: 700;
      gap        : 8px;
    }

    &__text {
      margin-top: 15px;
    }

    &__icon {
      color: var(--base-600);

    }
  }
}