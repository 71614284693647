.wallet-page {
  &__top {
    margin-top : 10px;
    display    : flex;
    align-items: center;

    .logo {
      height      : 30px;
      margin-right: 8px;
    }

    h3 {
      text-transform: capitalize;
    }
  }

  &__buttons {
    margin-left: auto
  }

  &__content {
    margin-top: 20px;
  }
}