/*
(tablet, pc)
*/
@mixin media($property, $values) {

  $val3: #{nth($values, 2)};
  $val2: #{nth($values, 1)};
  $val1: #{nth($values, 1)};

  @for $i from 0 to length($values) {

    @if $i+1==3 {
      $val3: #{nth($values, 3)};
      $val2: #{nth($values, 2)};
      $val1: #{nth($values, 1)};
    }
  }

  @if ($val1 !="") {
    @media screen and (max-width: 680px) {
      #{$property}: unquote(#{$val1});
    }
  }

  @if ($val2 !="") {
    @media screen and (min-width: 681px) and (max-width: 1023px) {
      #{$property}: unquote(#{$val2});
    }
  }

  @if ($val3 !="") {
    @media screen and (min-width: 1024px) {
      #{$property}: unquote(#{$val3});
    }
  }
}