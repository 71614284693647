svg.icon {
  height: 25px;
  width : 25px;

  &.medium {
    height: 20px;
    width : 20px;
  }

  &.small {
    height: 18px;
    width : 18px;
  }
}