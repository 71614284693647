.operations-page {
  &__header {
    display        : flex;
    justify-content: space-between;
  }

  &__title {
    span {
      display    : block;
      font-size  : 12px;
      line-height: 12px;
      font-weight: 500;
      color      : var(--base-700);
    }
  }

  &__filters {
    display    : flex;
    align-items: center;
    gap        : 20px;

    margin-left: 20px;
  }

  &__content {
    margin-top: 18px;
  }
}