@import "../../styles/mixins";

.admin-page {
  @include media(margin-top, (10px, ""));

  table {
    font-weight: 500;

    .name {
      display    : flex;
      align-items: center;
      gap        : 12px;
      font-size  : 14px;

      @include media(flex-wrap, (wrap, ""));

      img {
        width : 32px;
        height: 32px;
      }
    }
  }

  &__header {
    display        : flex;
    justify-content: space-between;
    gap            : 12px;

    @include media(flex-direction, (column, "", ""))
  }

  &__title {
    span {
      display    : block;
      font-size  : 12px;
      line-height: 12px;
      font-weight: 500;
      color      : var(--base-700);
    }
  }

  &__filters {
    display    : flex;
    align-items: center;

    @include media(gap, (10px, 20px, 20px));

    .input__search {
      width: 100%;
    }
  }

  &__content {
    margin-top: 18px;
  }
}