@import '../../styles/mixins';

.bots-trade-widget {
  transition: .3s;
  padding   : 16px;

  svg {
    overflow: visible;

    .apexcharts-yaxis {
      display: none;
    }
  }

  &.-test-bot {
    background: #fef9de;
  }

  &.-deactive {
    opacity: 0.6;
  }

  &__title {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    font-size      : 18px;
    font-weight    : 700;
    margin-bottom  : 8px;

    img.-icon-symbol {
      min-height  : 40px;
      min-width   : 40px;
      max-height  : 40px;
      max-width   : 40px;
      margin-right: 12px;
    }
  }

  &__desc {
    font-size  : 12px;
    font-weight: 400;
    line-height: 18px;
    color      : var(--base-700, #6A6F7F);
  }

  &__chart {
    height: 100px;
    width : calc(100% + 32px);
    margin: 0px -16px;

    img {
      width : 100%;
      height: 100%;
    }

    &.-reb {
      display: flex;
      @include media(flex-direction, (column, '', ''))
    }
  }

  &__pie {
    height: auto;
    width : calc(100% + 36px);
    margin: 0px -19px;

    img {
      width      : calc(100% + 32px);
      height     : 100%;
      margin-left: -16px;
    }
  }

  &__label {
    font-size      : 14px;
    padding-top    : 16px;
    display        : flex;
    justify-content: space-between;

    .-value {
      text-align : right;
      font-weight: 500;
    }

    .-price {
      font-size  : 18px;
      font-weight: 700;
    }
  }

  .-link {
    color      : var(--base-900);
    font-weight: 700;
  }
}