@import "../../styles/mixins";

.bot-info-page {
  &__chart {
    &.-reb {
      gap    : 15px;
      display: flex;
      @include media(flex-direction, (column, '', ''))
    }
  }
}