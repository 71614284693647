@import '../../styles/mixins';

header {
  top            : 0;
  background     : var(--base-900);
  width          : 100%;
  position       : fixed;
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  z-index        : 98;
  box-shadow     : 2px 2px 16px 0px rgba(44, 44, 44, 0.15);

  @include media(height, (var(--header-mobile-height), var(--header-pc-height)));
  @include media(padding, (12px 16px, 16px 32px 16px 14px));

  @media print {
    display: none !important;
  }

  >a {
    display: flex;
  }


  .mobile-menu {
    .icon {
      height: 30px;
      width : 30px;
      color : var(--base-600);

      &:hover {
        color: var(--primary-300);
      }
    }

    @include media(display, (block, none));
  }

  .user-block {
    margin-left: auto;
    align-items: center;
    gap        : 12px;
    display    : flex;

    &__profile {
      @include media(display, (none, none, flex));
    }

    &__profile,
    &__notifications {
      box-shadow : none;
      color      : var(--base-600);
      align-items: center;

      &:hover,
      &.active {
        color     : var(--primary-300);
        box-shadow: none;
      }

      .-avatar-icon,
      .-notifications-icon {
        width : 30px;
        height: 30px;
      }
    }
  }
}