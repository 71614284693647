@import '../../styles/mixins';

.bot-info-page {
  &__wrap {
    max-width: var(--max-width-content);
    margin   : auto;

    #tradingview-chart {
      margin: -1px;
      height: calc(100% + 2px);
      width : calc(100% + 2px);
    }

    .card {
      margin-top: 20px;
      @include media(margin-top, (10px, 20px));
      @include media(padding, (8px, 16px 24px, 16px 24px));
    }

    table {
      margin-top: 8px;
    }

    img.-icon-symbol {
      min-height  : 47px;
      min-width   : 47px;
      max-height  : 47px;
      max-width   : 47px;
      margin-right: 12px;
    }
  }

  &__content {
    display: flex;

    @include media(gap, (16px, 20px));
    @include media(flex-direction, (column, row, row));
  }

  &__info {
    min-width: 280px;
  }

  &__buttons {
    display: flex;
    gap    : 10px;
  }

  &__header {
    display        : flex;
    align-items    : center;
    justify-content: space-between;

    @include media(flex-wrap, (wrap, "", ""));
  }

  &__chart {
    width   : 100%;
    overflow: hidden;
    @include media(height, (500px, "", ""));
  }

  &__title {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    gap            : 10px;

    @include media(width, (100%, "", ""));

  }

  &__operations {
    margin-top: 24px;

    h4 {
      display        : flex;
      align-items    : center;
      justify-content: space-between;
    }
  }
}