@import '../../styles/mixins';

.menu-popup {
  padding       : 16px;
  background    : var(--base-0);
  display       : flex;
  flex-direction: column;
  gap           : 12px;
  border-radius : 8px;
  min-width     : 330px;
  right         : 0;
  z-index       : 10;
  visibility    : visible;
  opacity       : 1;
  pointer-events: all;
  transition    : .3s;

  @include media(box-shadow, (2px 2px 16px 0px rgb(0, 0, 0, 0.36), 2px 2px 16px 0px rgba(44, 44, 44, 0.15), 2px 2px 16px 0px rgba(44, 44, 44, 0.15)));
  @include media(position, (fixed, absolute, absolute));
  @include media(top, ("", 40px, 40px));
  @include media(left, (0, "", ""));
  @include media(bottom, (0, "", ""));
  @include media(overflow, (auto, "", ""));
  @include media(max-height, (calc(100vh - var(--header-mobile-height)), "", ""));

  &__wrap {
    position   : relative;
    display    : flex;
    align-items: center;
  }

  &__icon {
    height: 25px;
    width : 25px;
  }

  &.hide {
    visibility    : hidden;
    opacity       : 0;
    pointer-events: none;
  }

  &__click-outside {
    position: fixed;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
    z-index : 1;
  }
}