.tabs {
  height: 40px;

  a,
  .tab {
    margin-right  : 28px;
    font-size     : 16px;
    padding-bottom: 10px;
    box-shadow    : 0 1px;

    &:not(.active) {
      color     : var(--base-700);
      box-shadow: none;
    }

    &:hover {
      color: var(--primary-500);

    }

    &:last-child {
      margin: 0;
    }
  }
}